import { createStore } from 'redux';

import { 
    persistStore, 
    persistCombineReducers 
} from 'redux-persist';

import storage from 'redux-persist/lib/storage';

import app from './application/reducer';
import navigation from './navigation/reducer';
import categories from './categories/reducer';
import cache from './cache/reducer';

const reducers = persistCombineReducers({
    key: 'anytyme',
    version: 1,
    storage: storage,
    whitelist: ['app']
}, {
	app,
	navigation,
    categories,
    cache
});

const configureStore = () => {
    const store = createStore(reducers);
    const persistor = persistStore(store);
    return { store, persistor };
}

export default configureStore;